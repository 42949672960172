import React from "react";
import { EffectFade, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  ourgallaries,
  slideHome1,
  slidelefthometour,
  slidesFHome,
  slidetripadvisors,
  tours,
} from "../../utils/datasTest";
import "./index.scss";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { IoCamera } from "react-icons/io5";
import { MdSportsMotorsports } from "react-icons/md";
import { IoRestaurant } from "react-icons/io5";
import { FaPlay } from "react-icons/fa6";
import { IoChevronUpCircle } from "react-icons/io5";
import { PiTagSimpleFill } from "react-icons/pi";
import { IoCalendar } from "react-icons/io5";
import moment from "moment";
import LibrariesImgs from "../../components/_shared/ui-blocks/LibrariesImgs";
import Fancybox from "../../components/Fancybox";
import { FaWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { LiaTripadvisor } from "react-icons/lia";
import { IoCheckmarkDone } from "react-icons/io5";
import { TripLogo } from "../../assets";
import { dataDefaultFetch, postDataAPI } from "../../utils/fetchData";
import { Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";

function HomePage() {
  const swiperRef = React.useRef();

  const slideO = React.useRef();
  const slideZ = React.useRef();

  const [indexO, setIndexO] = React.useState(0);

  const [faqs, setfaqs] = React.useState([
    {
      id: 1,
      question: "How many people are in the Group Tour?",
      answer: `Our tour groups usually have 10 to 15 people per group.
       The groups may have different schedules but if you want, we will 
       support the groups to have time to interact and make friends. 
       You will definitely love this.
     `,
      open: true,
    },
    {
      id: 2,
      question: "How long does it take to complete the Ha Giang route?",
      answer: `
      The Ha Giang route can be completed in 3 or 4 days, depending on the tour you choose.
      3-day tour: This tour departs at 9:30 am from Sunflower Tours, 
      the trip is about 120 km each day and returns to Ha Giang city on the last day. 
      Each day will depend on the places you stop to check in, sightsee, the time may be shorter or longer.
      4-day tour: This tour covers about 90 - 100 km each day. 
      You will have plenty of time for activities and attractions. Although it is only one day longer, 
      it offers a richer experience with more sightseeing and fun activities.
      `,
      open: false,
    },
    {
      id: 3,
      question: "Where will I stay during the trip?",
      answer: `
      On the first day of arriving in Ha Giang city, visitors will be arranged a room to rest and shower for free. 
      When you return the car, there will be a free shower service after the trip.
      We will support booking homestay or hotel rooms depending on the needs of visitors.
      Towels, toothbrushes, shampoo, shower gel, ...: Provided at all homestays or hotels.
      `,
      open: false,
    },
    {
      id: 4,
      question: "What shuttle services does our tour include?",
      answer: `We support customers to book buses to travel from the northern provinces. 
      Depending on your needs, we have all types of vehicles: sleeper buses, limousines, 
      ... And we will definitely book round-trip buses for you.`,
      open: false,
    },
    {
      id: 5,
      question: "What are the differences between the vehicles?",
      answer: `
      Sleeper bus: Ideal for budget travelers looking for a basic, comfortable trip.
      Limousine bus: Provides more space and comfort, perfect for a more relaxed travel experience.
      Cabin bus: VIP sleeper bus is equipped with curtains and wifi, providing privacy and many amenities.
      `,
      open: false,
    },
    {
      id: 6,
      question:
        "If I take an overnight bus to Ha Giang, can I check in at Sunflower Tours?",
      answer: `
      When you arrive at our facility, you will first be arranged a room to rest, 
      shower and have free personal hygiene. Breakfast is served for free, 
      the tour departs at 9am every day, giving you plenty of time to prepare for the trip.
      `,
      open: false,
    },
    {
      id: 7,
      question: "Can I leave my backpack at Sunflower Tours?",
      answer: `
      It's completely free! We support keeping your belongings, 
      so you can rest assured during your trip because when you receive them back, 
      your belongings will be intact.
      `,
      open: false,
    },
    {
      id: 8,
      question: "Will I receive essential items?",
      answer: `
      When we deliver the vehicle to customers, 
      it will be the best vehicle equipped with protective gear such as (raincoats, helmets, winter gloves, straps, etc.).
      When customers return the vehicle, there will be a free shower and rest service after the trip.
      `,
      open: false,
    },
    {
      id: 9,
      question: "How much money should I bring when traveling to Ha Giang?",
      answer: `
      You should prepare about 1,000,000 VND - 2,000,000 VND for the trip because there will be no ATMs in the mountainous areas. 
      ATMs are only available in Ha Giang city, 
      but you should withdraw cash before starting the journey or even arriving in Ha Giang!
      `,
      open: false,
    },
    {
      id: 10,
      question: "When is the best time to travel to Ha Giang?",
      answer: `
      The ideal time to travel to Ha Giang is from October to April of the following year, 
      this is the period with great weather and climate.
      The rainy season from May to September can make the roads slippery and difficult to travel 
      and there is a high risk of landslides if it rains too much. You should consider this to make your trip complete.
      `,
      open: false,
    },
    {
      id: 11,
      question:
        "Do you need a motorbike license to rent a motorbike at Sunflower Tours?",
      answer: `Yes, you need a valid international motorbike license with the appropriate A1 stamp to rent a motorbike in Vietnam. 
      Because the police will check your documents unexpectedly during the trip. 
      But don't worry, choose a tour with a driver to help you, then the above will not happen.
      `,
      open: false,
    },
    {
      id: 12,
      question: "Is there mobile service on the Ha Giang route?",
      answer: `
      Mobile service is available on most of the Ha Giang route. 
      We recommend you use Viettel because it covers Northern Vietnam.
      `,
      open: false,
    },
    {
      id: 13,
      question: "Should I hire a driver or drive myself?",
      answer: `
        For first-time visitors to Ha Giang, I recommend you go with one of our backpackers. 
        You just need to sightsee and enjoy the wild beauty of Ha Giang. Our staff are all locals, they know the roads like the back of their hands. 
        They are also very good at communicating in English with foreigners, so you don't need to worry about the language.
      `,
      open: false,
    },
    {
      id: 14,
      question: "Why choose Sunflower Tours for your trip around Ha Giang?",
      answer: `
      Sunflower Tours is a family-run business that prides itself on providing exceptional service and local knowledge. 
      Our guides are experienced and passionate about sharing the beauty and culture of Ha Giang with our guests.
      `,
      open: false,
    },
  ]);

  const navigate = useNavigate();

  const [blogs, setBlogs] = React.useState(dataDefaultFetch);

  React.useEffect(() => {
    (async () => {
      setBlogs({ ...blogs, loading: true });
      try {
        const res = await postDataAPI("blog/all", {
          page: 0,
          limit_page: 5,
          filters: { is_delete: false },
        });

        setBlogs({ ...blogs, data: res.data.data, loading: false });
      } catch (error) {
        setBlogs({ ...blogs, data: null, loading: false, error: error });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTitle("Sun Flower Tours");

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        }

        return faq;
      })
    );
  };

  return (
    <div className="wrap-home">
      <div className="sl-1">
        <Swiper
          modules={[EffectFade]}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          speed={2500}
          slidesPerView={1}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {slidesFHome.map((d, k) => (
            <SwiperSlide key={k} className="wrap-slid-sh1">
              <img src={d.img} alt={`bg`} />
              <div>
                <div>
                  <h2>
                    {d.title.split(" ")[0]} <span>{d.title.split(" ")[1]}</span>
                  </h2>
                  <p>{d.short_text}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="btn-slide">
            <p onClick={() => swiperRef.current?.slidePrev()}>Previous</p>
            <p onClick={() => swiperRef.current?.slideNext()}>Next</p>
          </div>
        </Swiper>
      </div>
      <div className="sl-2">
        <div className="row">
          <div className="col-4">
            <div className="ct-l">
              <p>
                Sun <br /> Flower <br /> Tour
              </p>

              <div className="ct-l-bot">
                <h3>
                  <span>Travel</span> <br /> & Tours
                </h3>
              </div>
            </div>
            <figure>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/aboutus1.jpg?alt=media&token=45203ba8-08dc-4ff5-a027-b30e21ba9ebb"
                alt="anh21n34"
              />
            </figure>
          </div>
          <div className="col-4">
            <div className="bg-cv"></div>
            <div className="ct">
              <h2>About Us</h2>
              <p>
                Welcome to SunFlower's Tours Ha Giang website! Here, we extend
                an invitation for you to embark on an exhilarating adventure
                through the picturesque countryside of Vietnam— all from the
                saddle of a motorbike. Our motorbike tours promise a distinctive
                and authentic journey, revealing the breathtaking landscapes and
                hidden gems of this captivating country.
              </p>

              <button
                className="btn-main"
                onClick={() => {
                  navigate("/tour");
                }}
              >
                <p>Explore </p>
                <IoIosArrowRoundForward size={25} />
              </button>
            </div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2F104.jpg?alt=media&token=47afe772-73b3-40ac-bbc3-4575e1ba5717"
              alt="anh21n46"
            />
          </div>
          <div className="col-4">
            <div className="ct-l">
              <div className="ct-l-bot">
                <h3>
                  <span>Welcome</span> <br /> to us
                </h3>
              </div>
              <p>
                Ha <br /> Giang <br /> Loop
              </p>
            </div>
            <figure>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/aboutus2.jpg?alt=media&token=2cb04e0f-295d-4763-8c5e-d95f0bfdc476"
                alt="anh22n"
              />
            </figure>
          </div>
        </div>
      </div>

      <div className="sl-3">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2F119.jpg?alt=media&token=0bbe2d9e-b19f-4df2-9726-2d498bf84776"
          alt="anh21n"
        />
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
            <div className="bg-conti">
              <div>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Frmk2-removebg-preview.png?alt=media&token=f5e4e7ef-12ba-4bb3-9e89-705a30118f34"
                  alt="logo-sunflower"
                />
              </div>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
        <div className="content-up">
          <div className="txt-big-des">
            <h2>
              S<br />I<br />T<br />E<br />S
            </h2>
          </div>
          <div className="ct-box-sli-sl3">
            <div className="row">
              <div className="col">
                {slideHome1.map((i, k) => (
                  <div
                    className={`ct-up ${k === indexO ? "active" : ""}`}
                    style={{ display: k === indexO ? "block" : "none" }}
                    key={k}
                  >
                    <h3 className="desh1">{i.name_des}</h3>
                    <p className="desct1">{i.desc}</p>
                  </div>
                ))}
                <ul className="move-c-na">
                  <li onClick={() => slideO.current.slidePrev()}>
                    <IoChevronBack />
                  </li>
                  <li onClick={() => slideO.current.slideNext()}>
                    <IoChevronForward />
                  </li>
                </ul>
                {slideHome1.map((i, k) => (
                  <div
                    className={`circle-des-wrap ${
                      k === indexO ? "active-cir" : ""
                    }`}
                    style={{ display: k === indexO ? "block" : "none" }}
                    key={k}
                  >
                    <div className="circle-des">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fmapilengz.jpg?alt=media&token=aaf4b495-5753-4020-b8ee-73e70de0ca6f"
                        alt="anhnencircle"
                      />
                      <div className="circle-des-ct">
                        <p>{slideHome1[indexO].short_term}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col">
                <Swiper
                  modules={[Pagination]}
                  speed={1500}
                  onSlideChange={(i) => setIndexO(i.activeIndex)}
                  pagination={{
                    clickable: true,
                  }}
                  style={{
                    "--swiper-navigation-color": "#f59e0c",
                  }}
                  slidesPerView={1}
                  onBeforeInit={(swiper) => {
                    slideO.current = swiper;
                  }}
                >
                  {slideHome1.map((d, k) => (
                    <SwiperSlide key={k} className="slid-shf1">
                      <img src={d.url} alt={`bg`} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="sl-9"
        style={{
          backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fbjl.png?alt=media&token=eaa4f6ec-cce3-432b-8e95-13580239adc4"})`,
          backgroundSize: "cover",
        }}
        id="/about"
      >
        <div className="row">
          <div className="col-6">
            <figure>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/ourst.jpg?alt=media&token=8388a875-c50f-4589-ad67-bb27b5db22f1"
                alt="bgabouus"
              />
            </figure>
            <h3>Our Story</h3>
            <ul className="socials-contact">
              <li className="line-vertical"></li>
              <li>
                <p>Follow us</p>
              </li>
              <li className="ics-sc">
                <a
                  href={`https://wa.me/0327258338?text=Welcome to SunFlower Tours Hotline!`}
                  alt="link-whatsapp"
                  target="_blank"
                  rel="noreferrer"
                  itemProp="url"
                >
                  <FaWhatsapp />
                </a>
              </li>
              <li className="ics-sc">
                <a
                  href="https://www.instagram.com/hgtours_hagiangloop?igsh=d2ppZnIxbmxxM3Nr&utm_source=qr"
                  alt="link-ins"
                  target="_blank"
                  rel="noreferrer"
                  itemProp="url"
                >
                  <FaInstagram />
                </a>
              </li>
              <li className="ics-sc">
                <a
                  target="_blank"
                  rel="noreferrer"
                  alt="link-tripadvisor"
                  itemProp="url"
                  href="https://www.tripadvisor.com/Attraction_Review-g1544599-d27742983-Reviews-Ha_Giang_Motorbike_Loop-Ha_Giang_Ha_Giang_Province.html"
                >
                  <LiaTripadvisor />
                </a>
              </li>
              <li className="line-vertical"></li>
            </ul>
            <div
              className="tab-head-sm"
              style={{ backgroundImage: `url(${""})` }}
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fnjk.png?alt=media&token=ae083411-525d-4251-b20d-dc8813508128"
                alt="bgtabheadline"
              />
              <p>Sun Flower Tours</p>
            </div>
          </div>
          <div className="col-6">
            <h3>Introduction</h3>
            <p>
              Sunflower Tour was established in late 2019 by Mr. Dung, a young
              man with an ambitious dream of making international friends know
              more about the beautiful land of Ha Giang and its stunning
              scenery. Sunflower Tour is a family-run and managed company that
              always wants to bring guests experiences about the culture,
              landscape, cuisine, and friendliness of the people of the highland
              of Ha Giang in northern Vietnam.
            </p>
            <p>
              We are natives living in Ha Giang with many years of experience
              and a deep understanding of this area, sharing useful information
              services about the Ha Giang loop, places to visit, and constantly
              improving the quality of transportation, accommodation, and food
              services. Sunflower Tour is on a mission to promote local tourism
              development always striving to be creative and innovative,
              providing full support and advice to visitors when your trip.
            </p>
            <ul>
              <li>
                <span>
                  <IoCheckmarkDone />
                </span>
                Free route planning and maps
              </li>
              <li>
                <span>
                  <IoCheckmarkDone />
                </span>{" "}
                Free 24/7 support for travelers during the entire journey on the
                loop
              </li>
              <li>
                <span>
                  <IoCheckmarkDone />
                </span>
                Book accommodations along the way
              </li>
              <li>
                <span>
                  <IoCheckmarkDone />
                </span>
                Free luggage storage and shower for travelers who rent a car
                when returning from the route
              </li>
              <li>
                <span>
                  <IoCheckmarkDone />
                </span>
                Free bus ticket booking for subsequent trips
              </li>
            </ul>

            <div className="info-short">
              <div></div>
              <p>
                For further assistance, please contact us via email:&nbsp;
                <span>info.hgmotorbiketours@gmail.com</span> or WhatsApp:{" "}
                <span>+84327258338</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="sl-5">
        <div className="row sl-5-1">
          {tours.slice(0, 2).map((i, k) => (
            <div className="col-6" key={k}>
              <div className="row">
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/tour");
                  }}
                >
                  <div className="box-sale">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/hjk.png?alt=media&token=fe767c41-7da2-41cc-982e-3260595d4bb7"
                      alt="sale-tag-ic"
                    />

                    <p>
                      <span>Discount 250,000 VND/person</span>
                      <br />
                      <span>For groups of four or more</span>
                    </p>
                  </div>
                  <div
                    className={`tour-txt-link ${
                      (k + 1) % 2 === 0 ? "orihover" : "grehover"
                    }`}
                  >
                    <a href="/tour" alt="driect-link-tour">
                      Tour <br />
                      Details{" "}
                      {/* <span>
                        <FaChevronRight />
                      </span> */}
                    </a>
                  </div>

                  <img src={i.present_url} alt="anhtour3" />
                  <div className="ct-tour3-1">
                    <h3 className={`${(k + 1) % 2 === 0 ? "color-ori" : ""}`}>
                      {i.clear_des_title.split(" ").map((i, keycleardes) => (
                        <span key={keycleardes}>
                          {i}
                          <br />
                        </span>
                      ))}
                    </h3>
                  </div>
                  <p className="tour-txt-corner">
                    SUN <br /> FLOWER <br /> TOURS
                  </p>
                </div>
                <div className="col-6">
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fmpl.jpg?alt=media&token=b36f590e-472a-4533-be8f-77cd0abf4a2a"
                    }
                    alt="anhnena1"
                  />
                  <div
                    className={`bg-cv ${
                      (k + 1) % 2 === 0 ? "color-ori-img" : ""
                    }`}
                  ></div>
                  <h4>{i.max_date}</h4>
                  <div className="ct">
                    <div>
                      <h3>{i.clear_des_title}</h3>
                      {/* <p>{i.short_desc}</p> */}
                      <ul className="tour-in-ex">
                        <li>
                          <p>Tour Includes</p>
                          <p>{i.include_txt}</p>
                          <p>{i.help_txt}</p>
                        </li>
                        <li>
                          <p>Tour Excludes</p>
                          <p>{i.exclude_txt}</p>
                        </li>
                      </ul>
                      <ul className="packages">
                        {i.packages.map((i, kpage) => (
                          <li key={kpage}>
                            <p>{i.name}</p>
                            <p>{Number(i.pricevnd).toLocaleString("en-US")}đ</p>
                          </li>
                        ))}
                      </ul>
                      <ul className="service">
                        <li>
                          <IoRestaurant />
                          <p>
                            Greate <br /> Meals
                          </p>
                        </li>
                        <li>
                          <IoCamera />
                          <p>
                            Sightseeing <br /> Tours
                          </p>
                        </li>
                        <li>
                          <MdSportsMotorsports />
                          <p>
                            Motorcycle <br /> Experience
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="p-btn">
                      <button
                        className="btn-main"
                        onClick={() => {
                          navigate("/book");
                        }}
                      >
                        <p>Book Now</p>
                        <IoIosArrowRoundForward size={25} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row sl-5-2"></div>
      </div>

      <div className="sl-4">
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <a
                    data-fancybox-type="iframe"
                    data-fancybox="gallery"
                    data-src="https://www.youtube.com/embed/P7Mg3P3Ic9Q?si=vAYesXaYsfMaWFoa"
                    href="https://www.youtube.com/embed/P7Mg3P3Ic9Q?si=vAYesXaYsfMaWFoa"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/zi1.jpg?alt=media&token=384f3ec9-71d1-4867-90f5-ce3f8fe6bce1"
                      alt="vid1"
                    />
                  </a>
                  <div className="ab-pl">
                    <a
                      className="ab-pl-ic"
                      data-fancybox="gallery"
                      data-src="https://www.youtube.com/embed/P7Mg3P3Ic9Q?si=vAYesXaYsfMaWFoa"
                      href="https://www.youtube.com/embed/P7Mg3P3Ic9Q?si=vAYesXaYsfMaWFoa"
                    >
                      <FaPlay />
                    </a>
                  </div>
                </div>
                <div className="col-6">
                  <a
                    data-fancybox-type="iframe"
                    data-fancybox="gallery"
                    data-src="https://www.youtube.com/embed/w3Ky-jflB8E?si=Wma6ED8WbvYI64vc"
                    href="https://www.youtube.com/embed/w3Ky-jflB8E?si=Wma6ED8WbvYI64vc"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/zi2.jpg?alt=media&token=445f9133-ceda-4e88-8e6f-a186c73845d7"
                      alt="vid2"
                    />
                  </a>
                  <div className="ab-pl">
                    <a
                      className="ab-pl-ic"
                      data-fancybox-type="iframe"
                      data-fancybox="gallery"
                      data-src="https://www.youtube.com/embed/w3Ky-jflB8E?si=Wma6ED8WbvYI64vc"
                      href="https://www.youtube.com/embed/w3Ky-jflB8E?si=Wma6ED8WbvYI64vc"
                    >
                      <FaPlay />
                    </a>
                  </div>
                </div>
                <div className="col-6">
                  <a
                    data-fancybox-type="iframe"
                    data-fancybox="gallery"
                    data-src="https://www.youtube.com/embed/F_-3Rd65pzg?si=eMyqaJyBlgxoJ4y9"
                    href="https://www.youtube.com/embed/F_-3Rd65pzg?si=eMyqaJyBlgxoJ4y9"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/zi3.jpg?alt=media&token=627e941b-1cd7-4835-93ba-e34a88f5e810"
                      alt="vid2"
                    />
                  </a>
                  <div className="ab-pl">
                    <a
                      className="ab-pl-ic"
                      data-fancybox-type="iframe"
                      data-fancybox="gallery"
                      data-src="https://www.youtube.com/embed/F_-3Rd65pzg?si=eMyqaJyBlgxoJ4y9"
                      href="https://www.youtube.com/embed/F_-3Rd65pzg?si=eMyqaJyBlgxoJ4y9"
                    >
                      <FaPlay />
                    </a>
                  </div>
                </div>
                <div className="col-6">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fsv1.png?alt=media&token=9b70d4cf-acc1-4e44-a062-a657e1e05b3c"
                    alt="logosunflower2"
                  />
                  <div className="txt-op">
                    <h4>
                      Tour <br /> Experiences
                    </h4>
                  </div>
                  <h5>S</h5>
                </div>
              </div>
            </div>
            <div className="col-6">
              <Swiper
                modules={[Pagination]}
                speed={1500}
                direction="vertical"
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                style={{
                  "--swiper-navigation-color": "#f59e0c",
                  "--swiper-pagination-bullet-inactive-opacity": 0.4,
                }}
                slidesPerView={1}
                onBeforeInit={(swiper) => {
                  slideZ.current = swiper;
                }}
              >
                {slidelefthometour.map((d, k) => (
                  <SwiperSlide key={k} className="slid-right">
                    <div
                      data-fancybox-type="image"
                      data-fancybox="gallery"
                      data-src={d}
                    >
                      <img src={d} alt={`bg-fancy-left4${k}`} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <ul className="move-c-na">
                <li onClick={() => slideZ.current.slidePrev()}>
                  <IoChevronBack />
                </li>
                <li onClick={() => slideZ.current.slideNext()}>
                  <IoChevronForward />
                </li>
              </ul>
            </div>
          </div>
        </Fancybox>
      </div>
      <div className="sl-10">
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          <div className="container">
            <div className="logotrip">
              <TripLogo />
            </div>
            <div className="row">
              {slidetripadvisors.map((i, k) => (
                <div className="col" key={k}>
                  <figure data-fancybox="gallery" data-src={i}>
                    <img src={i} alt={`anhtrip${k}`} />
                  </figure>
                </div>
              ))}
              <div className="col">
                <a
                  target="_blank"
                  rel="noreferrer"
                  alt="link-tripadvisor"
                  href="https://www.tripadvisor.com/Attraction_Review-g1544599-d27742983-Reviews-Ha_Giang_Motorbike_Loop-Ha_Giang_Ha_Giang_Province.html"
                >
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Ftripsmedit.png?alt=media&token=8a635ce9-c61e-414e-8d34-6d56cd02861f"
                    alt="triplogosm"
                  />
                  <p>See more on Tripadvisor</p>
                </a>
              </div>
            </div>
          </div>
        </Fancybox>
      </div>
      <div className="sl-6">
        <div className="container">
          <h3 className="header-txt-homep">
            Frequently Asked Questions (FAQs)
          </h3>

          <div className="faqs">
            {faqs.map((faq, i) => (
              <div
                className={
                  "faq " + (faq.open ? `open ${i % 2 === 0 ? "ol" : "ev"}` : "")
                }
                key={i}
                onClick={() => toggleFAQ(i)}
              >
                <div className="tab-ques">
                  <div className="faq-question">{faq.question}</div>
                  <IoChevronUpCircle />
                </div>
                <div className="faq-answer">{faq.answer}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="sl-8"
        style={{
          backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fbgleft.png?alt=media&token=fd2ef699-df61-4c53-a143-1a5e4321c68e"})`,
        }}
      >
        <div className="container">
          <LibrariesImgs urls={ourgallaries} />
        </div>
      </div>
      <div className="sl-7">
        <div className="container">
          <h3 className="header-txt-homep">News</h3>
          <div className="row">
            {!blogs.loading && blogs.data ? (
              blogs.data?.length > 0 ? (
                <>
                  <div className="col-6">
                    <a
                      href={`/blog/detail/${blogs.data[0]._id}`}
                      onClick={() => {
                        navigate(`/blog/detail/${blogs.data[0]._id}`, {
                          state: { title: blogs.data[0].title },
                        });
                      }}
                      alt={`blog1`}
                    >
                      <figure>
                        <img src={blogs.data[0].img?.url} alt="img_blog1" />
                      </figure>
                    </a>

                    <div className="info-icons">
                      <div className="tag">
                        <PiTagSimpleFill />

                        <p>{blogs.data[0].categories}</p>
                      </div>
                      <div className="time">
                        <IoCalendar />

                        <p>
                          {moment(blogs.data[0].createdAt).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>

                    <p
                      className="tbblog1"
                      onClick={() => {
                        navigate(`/blog/detail/${blogs.data[0]._id}`, {
                          state: { title: blogs.data[0].title },
                        });
                      }}
                    >
                      {blogs.data[0]?.title}
                    </p>
                    <p className="scbblog1">{blogs.data[0]?.short_content}</p>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      {blogs.data.slice(1, 5).map((i, k) => (
                        <div key={k} className="col-6">
                          <a
                            href={`/blog/detail/${i._id}`}
                            onClick={() =>
                              navigate(`/blog/detail/${i._id}`, {
                                state: { title: i.title },
                              })
                            }
                            alt={`blog${k + 1}`}
                          >
                            <figure>
                              <img
                                src={i.img?.url}
                                srcSet={i.img?.url}
                                alt={`img_blog${k + 1}`}
                              />
                            </figure>
                          </a>
                          <div className="info">
                            <div className="info-icons">
                              <div className="tag">
                                <PiTagSimpleFill />

                                <p>{i.categories}</p>
                              </div>
                              <div className="time">
                                <IoCalendar />

                                <p>
                                  {moment(i.createdAt).format("DD-MM-YYYY")}
                                </p>
                              </div>
                            </div>
                            <a
                              href={`/blog/detail/${i._id}`}
                              alt={`blog${k + 1}`}
                              onClick={() =>
                                navigate(`/blog/detail/${i._id}`, {
                                  state: { title: i.title },
                                })
                              }
                            >
                              {i.title}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <p>None of any blog here!</p>
              )
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
